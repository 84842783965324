import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DeleteButton,
  TextInput,
} from "react-admin";

export const DemandeList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="membre_id" />
      <TextField source="service_id" />
      <TextField source="objet" />
      <TextField source="corps" />
      <TextInput source="status" />
      <DeleteButton />
    </Datagrid>
  </List>
);