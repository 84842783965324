import * as React from "react";
import { List, Datagrid, TextField, DateField} from 'react-admin';

export const MemberList = props => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="nom" />
            <TextField source="prenom" />
            <TextField source="email" />
            <TextField source="pays" />
            <TextField source="telephone" />
            <TextField source="adresse" />
            <TextField source="profession" />
            <TextField source="region" />
            <DateField source="date_naissance" />
            <TextField source="region" />
            <TextField source="sexe" />
            <TextField source="secteur" />
        </Datagrid>
    </List>
);

