import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  FileField,
  FileInput,
  ImageField,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";
import environment from './config/environment';

export const PartenaireList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="vendor_id" />
      <TextField source="nom" />
      <ImageField source="logo" label="Logo" />
      <TextField source="lien_redirection" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

export const PartenaireEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append("nom", values.nom);
    formData.append("lien_redirection", values.lien_redirection);
    if (values.logo instanceof File) {
      formData.append("logo", values.logo, values.logo.name);
    } else if (values.logo && values.logo.rawFile instanceof File) {
      formData.append("logo", values.logo.rawFile, values.logo.rawFile.name);
    }
    try {
      const response = await fetch(`${environment.apiUrl}/partenaire/${values.id}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      notify('Partenaire modifié avec succès', { type: 'info' });
      redirect('list', '/partenaire');
      refresh();
    } catch (error) {
      notify(`Erreur: ${error.message}`, { type: 'warning' });
      console.error("Failed to edit partenaire:", error);
    }
  };

  return (
    <Edit {...props}>
      <SimpleForm onSubmit={handleSubmit}>
        <TextInput source="nom" />
        <FileInput source="logo" accept="image/*" multiple={false}>
          <FileField source="src" title="title" />
        </FileInput>
        <TextInput source="lien_redirection" />
      </SimpleForm>
    </Edit>
  );
};

export const PartenaireCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append("nom", values.nom);
    formData.append("lien_redirection", values.lien_redirection);
    if (values.logo instanceof File) {
      formData.append("logo", values.logo, values.logo.name);
    } else if (values.logo && values.logo.rawFile instanceof File) {
      formData.append("logo", values.logo.rawFile, values.logo.rawFile.name);
    }
    try {
      const response = await fetch(`${environment.apiUrl}/partenaire`, {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      notify('Partenaire créé avec succès', { type: 'info' });
      redirect('list', '/partenaire');
      refresh();
    } catch (error) {
      notify(`Erreur: ${error.message}`, { type: 'warning' });
      console.error("Failed to create partenaire:", error);
    }
  };

  return (
    <Create {...props}>
      <SimpleForm onSubmit={handleSubmit}>
        <TextInput source="nom" />
        <FileInput source="logo" accept="image/*" multiple={false}>
          <FileField source="src" title="title" />
        </FileInput>
        <TextInput source="lien_redirection" />
      </SimpleForm>
    </Create>
  );
};
