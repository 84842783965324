import * as React from "react";
import { Admin, Resource, nanoLightTheme, nanoDarkTheme } from "react-admin";
import restProvider from "./dataProvider";
import { MemberList } from "./members";
import { UserList } from "./user";
import { PartenaireList, PartenaireEdit, PartenaireCreate } from "./partenaire";
import { DemandeList } from "./demande";
import { MessageList } from "./message";
import { ServiceList} from "./service";
import { TypeArticleList, TypeArticleCreate } from "./type_article";
import { ArticleList, ArticleEdit, ArticleCreate } from "./article";

import MemberIcon from "@mui/icons-material/Person";
import RequestIcon from "@mui/icons-material/Assignment";
import ServiceIcon from "@mui/icons-material/Build";
import MessageIcon from "@mui/icons-material/Message";
import PartnerIcon from "@mui/icons-material/Business";
import TypeArticleIcon from "@mui/icons-material/Category";
import ArticleIcon from "@mui/icons-material/Article";
import UserIcon from "@mui/icons-material/Group";

const App = () => (
  <Admin
    dataProvider={restProvider}
    theme={nanoLightTheme}
    darkTheme={nanoDarkTheme}
  >
    <Resource icon={MemberIcon} name="members" list={MemberList} />
    <Resource
      icon={PartnerIcon}
      name="partenaire"
      list={PartenaireList}
      edit={PartenaireEdit}
      create={PartenaireCreate}
    />
    <Resource icon={RequestIcon} name="demande" list={DemandeList} />
    <Resource icon={MessageIcon} name="message" list={MessageList} />
    <Resource
      icon={ServiceIcon}
      name="service"
      list={ServiceList}

    />
    <Resource
      icon={TypeArticleIcon}
      name="type_article"
      list={TypeArticleList}
      create={TypeArticleCreate}
    />
    <Resource
      icon={ArticleIcon}
      name="article"
      list={ArticleList}
      create={ArticleCreate}
      edit={ArticleEdit}
    />
    <Resource icon={UserIcon} name="user" list={UserList} />
  </Admin>
);

export default App;
