import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
} from "react-admin";
export const ServiceList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="nom" />
      <TextField source="description" />
      <TextField source="responsable" />
      <TextField source="adresse" />
      <TextField source="horaire" />
      <TextField source="contact" />
      <TextField source="Media_images" />
      <TextField source="Media_videos" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);
