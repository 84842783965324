import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import environment from './config/environment';
const apiUrl = environment.apiUrl;
const httpClient = fetchUtils.fetchJson;
const handleResponse = response => {
    return response.text().then(text => {
        let json;
        try {
            json = JSON.parse(text);
        } catch (e) {
            return Promise.reject(new Error('Invalid JSON: ' + text));
        }

        if (!response.ok) {
            const error = new Error(response.statusText);
            error.body = json;
            return Promise.reject(error);
        }
        return json;
    });
};
const dataProvider = {
    getList: (resource, params) => {
        const url = `${apiUrl}/${resource}`;
        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: json.length,
        }));
    },

    getOne: (resource, params) => {
        const url = `${apiUrl}/${resource}/${params.id}`;
        return httpClient(url).then(({ json }) => ({
            data: json,
        }));
    },

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({
            data: json,
        }));
    },

    getManyReference: (resource, params) => {
        const query = {
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({
            data: json,
            total: json.length,
        }));
    },

    update: (resource, params) => {
        const url = `${apiUrl}/${resource}/${params.id}`;
        return httpClient(url, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: json,
        }));
    },

    updateMany: (resource, params) => {
        const url = `${apiUrl}/${resource}`;
        return httpClient(url, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: json,
        }));
    },

    create: (resource, params) => {
        const url = `${apiUrl}/${resource}`;
        const formData = new FormData();
        const isFormData = "media_images" in params.data;
        let body = JSON.stringify(params.data);
        if (isFormData) {
          body = new FormData();
  
          Object.keys(params.data).forEach((key) => {
            if (key === "media_images") {
              if (!params.data[key].rawFile) throw new Error("File is required");
              body.append(key, params.data[key].rawFile);
            } else {
              body.append(key, params.data[key] ?? "");
            }
          });
        }
  
        const headers = isFormData ? {} : { "Content-Type": "application/json" };
  
        return fetch(url, {
            method: 'POST',
            body: formData,
        })
        .then(handleResponse)
        .then(json => {
            if (!json || json.id === undefined) {
                return Promise.reject(new Error('Invalid response from server'));
            }
            return { data: { ...params.data, id: json.id } };
        })
        .catch(error => Promise.reject(error));
    },

    delete: (resource, params) => {
        const url = `${apiUrl}/${resource}/${params.id}`;
        return httpClient(url, {
            method: 'DELETE',
        }).then(({ json }) => ({
            data: json,
        }));
    },

    deleteMany: (resource, params) => {
        const url = `${apiUrl}/${resource}`;
        return httpClient(url, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: json,
        }));
    },
};

export default dataProvider;