import * as React from "react";
import { List, Datagrid, TextField,DeleteButton} from 'react-admin';

export const MessageList = props => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="nom" />
            <TextField source="prenom" />
            <TextField source="email" />
            <TextField source="telephone" />
            <TextField source="objet" />
            <TextField source="corps" />
            <DeleteButton />
        </Datagrid>
    </List>
);


